<template>
  <form
    enctype='multipart/form-data'
    @submit.prevent='submit'
  >
    <div class="row">
      <loading-notification :show="loading" />
    </div>
    <div class='row'>
      <div class='flex xs12 md6'>
        <va-input
          color="info"
          v-model="form.name.value"
          :disabled="loading"
          :label="$t('layout.form.nameInput')"
          :error="!!form.name.errors.length"
          :error-messages="translatedErrors(form.name.errors)"
          @blur="validate('name')"
        />
      </div>
      <div class='flex xs12 md6'>
        <va-select
          v-model="selectedType"
          :label="$t('resources.inputs.typeInput')"
          :options="typeOptions"
          :loading="loading"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          key-by="value"
          text-by="name"
          searchable
        />
      </div>
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-input
          color="info"
          v-model="form.path.value"
          type="url"
          :disabled="loading"
          :label="$t('layout.form.urlInput')"
          :error="!!form.path.errors.length"
          :error-messages="translatedErrors(form.path.errors)"
          @blur="validate('path')"
        />
      </div>
    </div>
    <div class="row">
      <div class="flex xs12 md6">
        <h6>{{ $t('resources.inputs.preview') }}</h6>
        <picture-input
          ref="preview"
          width="300"
          height="300"
          margin="16"
          size="10"
          :crop="false"
          :prefill="prefill.preview"
          button-class="va-button va-button--flat color--primary"
          :custom-strings="imageTranslations"
          @change="onChange('preview', 'preview')"
        />
      </div>
      <div class="flex xs12 md6">
        <va-input
          color="info"
          v-model="form.description.value"
          type="textarea"
          :autosize="true"
          :min-rows="5"
          :disabled="loading"
          :label="$t('layout.form.descriptionInput')"
          :error="!!form.description.errors.length"
          :error-messages="translatedErrors(form.description.errors)"
          @blur="validate('description')"
        />
      </div>
    </div>

    <div class='row send-button'>
      <div class='flex xs12'>
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { validatorMixin } from '@/services/validator'
const PictureInput = () => import(/* webpackPrefetch: true */ 'vue-picture-input')

export default {
  name: 'resources-form',
  components: {
    PictureInput,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: Object,
      required: true,
    },
  },
  mixins: [validatorMixin],
  computed: {
    isNew () {
      return this.resource.id === undefined
    },
    charsLeft () {
      const chars = this.resource.description || ''
      return 140 - chars.length
    },
    typeOptions () {
      const types = [
        {
          name: this.$t('resources.types.docx'),
          value: 0,
        },
        {
          name: this.$t('resources.types.xlsx'),
          value: 1,
        },
        {
          name: this.$t('resources.types.pdf'),
          value: 2,
        },
        {
          name: this.$t('resources.types.jpg'),
          value: 3,
        },
        {
          name: this.$t('resources.types.mp4'),
          value: 4,
        },
        {
          name: this.$t('resources.types.mp3'),
          value: 5,
        },
        {
          name: this.$t('resources.types.unknown'),
          value: 6,
        },
        {
          name: this.$t('resources.types.pptx'),
          value: 7,
        },
      ]

      return types
    },
  },
  data () {
    return {
      prefill: {
        preview: null,
      },
      images: {
        preview: null,
      },
      deleteResource: false,
      selectedType: '',
      currentDocument: [],
      imageTranslations: {
        upload: this.$t('layout.images.upload_error'),
        drag: this.$t('layout.images.drag'),
        tap: this.$t('layout.images.tap'),
        change: this.$t('layout.images.change'),
        remove: this.$t('layout.images.remove'),
        select: this.$t('layout.images.select'),
        selected: this.$t('layout.images.selected'),
        fileSize: this.$t('layout.images.file_size', { size: 10 }),
        fileType: this.$t('layout.images.file_type'),
        aspect: this.$t('layout.images.aspect'),
      },
      form: {
        name: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        path: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        description: {
          value: '',
          validate: {
            maxLength: 140,
          },
          errors: [],
        },
      },
    }
  },
  watch: {
    resource (val) {
      this.setResource(val)
    },
    currentDocument (val) {
      this.filesChange()
    },
    currentLocale (val) {
      this.validateAll()
    },
  },
  created () {
    this.initialData()
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    async setResource (val) {
      this.setFormData(val)
      if (Number.isInteger(val.type)) {
        const type = this.typeOptions.find(t => t.value === val.type)
        this.selectedType = type || this.typeOptions[0]
      }
      if (val.preview) {
        let base = ''
        if (process.env.NODE_ENV === 'development') {
          base = this.$http.defaults.baseURL.match(/^https?:\/\/[^/]+/g)[0]
        }
        this.prefill.preview = base + val.preview
      }

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    initialData () {
      if (this.resource) {
        this.setResource(this.resource)
      }
    },
    onChange (source, ref) {
      if (!this.$refs[ref].file) {
        return console.error('FileReader API not supported: use the <form>, Luke!')
      }
      this.images[source] = this.$refs[ref].file
    },
    exceedSize () {
      const maxBytes = 8388608
      return this.currentDocument[0].size > maxBytes
    },
    filesChange () {
      if (this.currentDocument.length === 0) {
        return
      }
      if (this.exceedSize()) {
        this.showToast(this.$t('notifications.network.file_size', { size: '8' }), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 30000,
          fullWidth: false,
        })
        this.currentDocument = []
        return
      }

      this.setMime(this.currentDocument[0].type)
    },
    setMime (mime) {
      const mimes = [
        { name: 'audio/', type: 5 },
        { name: 'video/', type: 4 },
        { name: 'image/', type: 3 },
        { name: 'application/pdf', type: 2 },
        { name: 'application/vnd.ms-excel', type: 1 },
        { name: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', type: 1 },
        { name: 'application/vnd.ms-powerpoint', type: 7 },
        { name: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', type: 7 },
        { name: 'text/plain', type: 0 },
        { name: 'application/msword', type: 0 },
        { name: 'application/vnd.oasis.opendocument.text', type: 0 },
        { name: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', type: 0 },
      ]

      let typed = false
      for (const m of mimes) {
        if (mime.includes(m.name)) {
          this.selectedType = this.typeOptions.find(t => t.value === m.type)
          typed = true
          break
        }
      }

      if (!typed) {
        this.selectedType = {
          name: this.$t('resources.types.unknown'),
          value: 6,
        }
      }
    },
    async submit () {
      this.validateAll()
      if (!this.formReady) return

      const resource = new FormData()
      if (this.resource.id) {
        resource.append('id', this.resource.id)
        resource.append('_method', 'PUT')
      }

      resource.append('name', this.getFormValue('name'))
      resource.append('path', this.getFormValue('path'))
      resource.append('description', this.getFormValue('description'))
      if (this.selectedType) {
        resource.append('type', this.selectedType.value)
      }
      if (this.deleteResource) {
        resource.append('delete', this.deleteResource)
      }
      if (this.images.preview) {
        resource.append('preview', this.images.preview)
      }

      if (this.currentDocument.length > 0) {
        try {
          resource.append('path', this.currentDocument[0])
          resource.append('filesize', this.currentDocument[0].size)
        } catch (e) {
          // console.log('No document', [this.currentDocument, e])
        }
      }

      this.$emit('submit', resource)
    },
  },
}
</script>

<style scoped>
.send-button {
  margin-top: 25px;
}
</style>
